import React from 'react';

import hospitalImage from '../../assets/hospital_image_wider.jpg';
import icon_mission from '../../assets/icon_mission.png';
import icon_values from '../../assets/icon_values.png';
import icon_vision from '../../assets/icon_vision.png';

const AboutUs = () => {
    return (
        <div className='container-fluid px-0 my-2'>
            <img src={hospitalImage} alt="Hospital Brain and Spine Center" style={{width: '100%'}} />

            <div className='px-5 pt-3'>
                <h4 style={{ color: '#0E7361' }}>ABOUT US</h4>
                <p>
                        Tophill Hospital, Brain and Spine Centre is a Level 5 comprehensive healthcare provider located in Elgon View, Eldoret.
                        It is managed by dedicated professionals with many years of experience in the medical field. Our aim is to provide comprehensive
                        quality services at an afforadable cost to the community. We are fully equipped with state-of-the-art facilities and equipment to 
                        attend critical surgeries as well as routine medical procedures.
                </p>

                <div className='row row-cols-md-3'>
                    <div className='col d-flex align-items-center'>
                        <img src={icon_vision} alt="Vision Icon" className="rounded-2 me-3" style={{ width: '50px', height: '50px', padding: '10px', backgroundColor: 'rgba(14, 115, 97, 0.1)' }} />
                        <div className="d-flex flex-column justify-content-center">
                            <p className="fw-bold mb-2">OUR VISION</p>
                            <p>To Offer Excellent Healthcare Service.</p>
                        </div>
                    </div>

                    <div className='col d-flex align-items-center'>
                        <img src={icon_mission} alt="Mission Icon" className="rounded-2 me-3" style={{ width: '50px', height: '50px', padding: '10px', backgroundColor: 'rgba(14, 115, 97, 0.1)' }} />
                        <div className="d-flex flex-column justify-content-center">
                            <p className="fw-bold mb-2">OUR MISSION</p>
                            <p>To provide comprehensive health services through innovative technology and research.</p>
                        </div>
                    </div>

                    <div className='col d-flex align-items-center'>
                        <img src={icon_values} alt="Values Icon" className="rounded-2 me-3" style={{ width: '50px', height: '50px', padding: '10px', backgroundColor: 'rgba(14, 115, 97, 0.1)' }} />
                        <div className="d-flex flex-column justify-content-center">
                            <p className="fw-bold mb-2">OUR CORE VALUES</p>
                            <p>Compassion - Integrity - Teamwork - Excellence - Patient Safety - Patient Confidentiality.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AboutUs;

import React from 'react';
import icon_clock from '../../assets/icon_clock.png';

const openingHours = [
    { day: 'AFTERNOON SESSION', hours: '01:00 PM - 02:00 PM' },
    { day: 'EVENING SESSION', hours: '04:00 PM - 06:00 PM' },
];

const VisitingHours = () => {
    return (
        <div className="mb-4">
            <h5>Inpatient Visiting Hours</h5>
            {openingHours.map((item, index) => (
                <div className="d-flex align-items-center mb-3" key={index}>
                    <img src={icon_clock} alt="Clock Icon" className="img-fluid"
                         style={{ width: '50px', height: '50px', padding: '10px' }}/>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="fw-bold mb-3" style={{ fontSize: "12px"}}>{item.day}</p>
                        <p className="fw-light mb-0" style={{ fontSize: "12px", marginTop: "-12px" }}>{item.hours}</p>
                    </div>
                </div>
            ))}

            <h5 className='mt-4'> Outpatient Services</h5>
            <p style={{ fontSize: "12px"}}>Our outpatient services are available round the clock, every day of the week.</p>

        </div>
    );
}

export default VisitingHours;

import React from 'react';
import './services.scss';

const servicesList = [
  { category: 'NEUROSURGERY', description: 'Brain & Spine Care' },
  { category: 'ORTHOPAEDICS', description: 'All Things Bone & Muscle' },
  { category: 'GENERAL SURGERY & INTERNAL MEDICINE', description: 'Common Ailments, Hypertension, Diabetes' },
  {
    category: "PEDIATRICS & CHILD HEALTH",
    description: "Common Childhood Ailments. Keeping Children Well.",
  },
  {
    category: "OBSTETRICS & GYNAECOLOGY",
    description: "Safe Motherhood & Reproductive Health",
  },
  {
    category: "MENTAL HEALTH & COUNSELLING",
    description: "Get Back Your Bounce!",
  },
  {
    category: "RENAL MEDICINE",
    description: "Prevention, Treatment & Care Of Kidney Diseases",
  },
  {
    category: "ONCOLOGY",
    description: "Prevention, Treatment & Palliative Care Of Cancer",
  },
  {
    category: "CARDIOLOGY",
    description: "Prevention & Treatment Of Heart Conditions",
  },
  {
    category: "EAR, NOSE & THROAT (ENT)",
    description: "Prevention & Treatment",
  },
  {
    category: "DERMATOLOGY",
    description: "Maintain That Perfect Skin",
  },
  {
    category: "OPHTHALMOLOGY",
    description: "Keeping Your Vision At 20/20",
  }
];

const Services = () => {
  return (
    <div className="services-container container mt-4">
        <div className="row">
            <div className="col-sm-4 align-self-center">
                <h4 style={{ color: '#0E7361' }}>OUR SERVICES</h4>
                <p>
                  
Here at Tophill, we aim to clear away your pain and discomfort through the 
revolutionary services offered by a highly skilled and professionally subspecialiyed medical personel
equipped with state-of-the-art facilities and equipment to bring about a fast diagnosis and treatment  of
ailments to ensure recovery in no time. Feel at ease in the hands of our caring nurses and comfortable 
in-patient wards ranging from maternity, pediatric, casual and private wards which we assure brings an
enjoyable time during covalescence. be greeted with our friendly front office and customer care who 
are always available to direct and help with any enquiries. Welcome and enjoy extra-ordinary care.
                </p>
            </div>
            <div className="col-sm-7 mt-4 ms-md-5">
                {servicesList.map((service, index) => (
                    <div key={index} className="service-category">
                        <h6>{service.category}</h6>
                        <p>{service.description}</p>
                        <hr/>
                    </div>
                )
                )}
            </div>
        </div>
    </div>
  );
};

export default Services;

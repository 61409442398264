import React from 'react';
import contact_background from '../../assets/contact_background_overlay.png';

import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import VisitingHours from './VisitingHours';

const ContactSection = () => {
    const outerBackgroundStyle = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: `url(${contact_background})`,
        height: 'auto',
        width: '100%'
    };

    return (
        <div className="container-fluid mt-4 pt-md-4" style={{ backgroundColor: '#0E7361'}}>
           <div  className="row justify-content-center text-white px-3 py-3" style={outerBackgroundStyle}>
            <div className='col-md-6 px-md-4'>
                <VisitingHours />
            </div>

            <div className='col-md-6 px-md-4'>
                <ContactForm />
            </div>

            <hr className='mt-4'/>

            <ContactInfo />

           </div>
        </div>

    );
};

export default ContactSection;

import React from 'react';

const Footer = () => {
    return (
        <div className="text-white text-center py-1 pt-3" style={{ backgroundColor: '#0E7361' }}>
            <div className="mx-auto mb-2" ></div>
            <div>
                <p style={{ fontSize: "12px" }}>&copy; 2023 Tophill. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default Footer;

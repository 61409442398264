import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

import './Landing.scss';

import handWithStethoscope2x from '../../assets/hand_with_stethoscope_2x.png';
import iconChat from '../../assets/icon_chat.png';
import tophillLogo from '../../assets/tophill_logo.png';

const Landing = () => {

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }

  return (
    <div className="container-fluid landing-background">
      <div className="row">
        <div className="col-md-8">

          <div className='row align-items-center'>
            <div className="col d-flex justify-content-md-start justify-content-center">
              <img src={tophillLogo} alt="Logo" className="logo-img" />
            </div>
           
            <div className="col d-none d-md-block menu-items mx-md-2 invisible">
              <a href="#services" className="menu-item me-4">Services</a>
              <a href="#about" className="menu-item me-4">About Us</a>
              <a href="#contact" className="menu-item">Get in Touch</a>
            </div>
          </div>

          <div className='row my-3 my-md-5 mb-md-0'>
            <div className='col-md-9 px-4 mt-4 p-md-0 m-md-5'>
            <span className="mini-banner py-1 px-2 fw-bold rounded karla">
              Your Health Comes First
            </span>
            <h2 className='text-uppercase fw-bolder py-2 karla'>
              your ultimate neuro- <br/> and spine care destination
            </h2>
            <p className='poppins'>
              At Tophill, your neurological, spine and general health is our top priority. As leaders in neuroscience and spine surgery, our team of dedicated surgeons,
              specialists and clinicians harness groundbreaking research and technological to deliver unmatched patient outcomes.
            </p>

            <button onClick={() => handleScroll()} className="btn btn-appt my-5 text-uppercase align-items-center d-flex" >
              <img src={iconChat} alt="Chat Icon" style={{ marginRight: '8px' }} />
              <p className='mb-0'>Schedule Your Appointment</p>
            </button>

            </div>
          </div>
          
        </div>

        <div className="col-md-4 d-none d-md-block p-0">
          <img src={handWithStethoscope2x} alt="Helpful Stethoscope" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Landing;

import React from 'react';
import icon_facebook from '../../assets/icon_facebook_white.png';
import icon_instagram from '../../assets/icon_instagram_white.png';
import icon_linkedIn from '../../assets/icon_linkedIn_white.png';
import icon_location from '../../assets/icon_location.png';
import icon_phone from '../../assets/icon_phone.png';
import icon_social_media from '../../assets/icon_social_media_white.png';


const ContactInfo = () => {
    return (
        <div className="row px-5 pt-3 pb-4 text-white">
                <div className="col-md-4 mb-3 mb-md-0 d-flex align-items-center">
                    <img src={icon_location} alt="Location Icon" className="img-fluid me-2"
                         style={{ width: '50px', padding: '10px'}}/>
                    <div>
                        <p className="fw-bold mb-3">ADDRESS</p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Garden Road, Elgon View</p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Next to Boma INN Hotel</p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>P.O Box 6689-30100</p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Eldoret, Kenya</p>
                    </div>
                </div>
                <div className="col-md-4 mb-3 mb-md-0 d-flex align-items-center">
                    <img src={icon_phone} alt="Phone Icon" className="img-fluid me-2" style={{ width: '50px', padding: '10px'}}/>
                    <div>
                        <p className="fw-bold mb-3">PHONE</p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Landline: 0782121214 </p>
                        <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>WhatsApp: 0782900090 </p>
                    </div>
                </div>
                <div className="col-md-4 mb-3 mb-md-0 d-flex align-items-center">
                    <img src={icon_social_media} style={{ width: '50px', padding: '10px'}}
                         alt="Social Media Icon" className="img-fluid me-2" />
                    
                    <div>
                       <p className="fw-bold mb-1">SOCIAL MEDIA</p>
                       <p style={{ fontSize: "12px", marginTop: "-8px" }}>Get ready to engage with us soon.</p>
                        <div className="d-flex">
                            <img src={icon_facebook} alt="Facebook Icon" className="img-fluid me-3"
                                 style={{ height: '25px', width: '25px' }} />
                            <img src={icon_linkedIn} alt="LinkedIn Icon" className="img-fluid me-3"
                                 style={{ height: '25px', width: '25px' }} />
                            <img src={icon_instagram} alt="Instagram Icon" className="img-fluid"
                                 style={{ height: '25px', width: '25px' }} />
                        </div>
                    </div>
                </div>
            </div>
        );

        /*
        <div className="row justify-content-center text-white py-3 px-5"
                 style={{ width: '100%', backgroundColor: '#0E7361' }}>
            <div className="col-12 col-md-4 d-flex align-items-center">
                <div className="col-2">
                    <img src={icon_location} alt="Location Icon" className="img-fluid" />
                </div>
                <div className="col-10">
                    <p className="fw-bold mb-3">ADDRESS</p>
                    <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Line 1</p>
                    <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Line 2</p>
                    <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Line 3</p>
                </div>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-center">
                <div className="col-2">
                    <img src={icon_phone} alt="Phone Icon" className="img-fluid" />
                </div>
                <div className="col-10">
                    <p className="fw-bold mb-3">PHONE</p>
                    <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>Landline: </p>
                    <p className="fw-light mb-2" style={{ fontSize: "12px", marginTop: "-8px" }}>WhatsApp</p>
                </div>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-center">
                <div className="col-2">
                    <img src={icon_social_media}  style={{ width: '40%' }}
                         alt="Social Media Icon" className="img-fluid" />
                </div>
                <div className="col-10">
                    <p className="fw-bold mb-3">SOCIAL MEDIA</p>
                    <div className="row">
                        <div className="col-2">
                            <img src={icon_facebook} alt="Facebook Icon" className="img-fluid"
                            style={{ height: '25px', width: '25px' }}/>
                        </div>
                        <div className="col-2">
                            <img src={icon_linkedIn} alt="LinkedIn Icon" className="img-fluid"
                            style={{ height: '25px', width: '25px' }}/>
                        </div>
                        <div className="col-2">
                            <img src={icon_twitter} alt="Twitter Icon" className="img-fluid"
                            style={{ height: '25px', width: '25px' }}/>
                        </div>
                        <div className="col-2">
                            <img src={icon_instagram} alt="Instagram Icon" className="img-fluid"
                            style={{ height: '25px', width: '25px' }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        */
}

export default ContactInfo;

import './App.scss';
import AboutUs from './components/AboutUs/AboutUs';
import ContactSection from './components/ContactSection/ContactSection';
import Footer from './components/Footer/Footer';
import Landing from './components/Landing/Landing';
import Services from './components/Services/services';

function App() {

  return (
      <div>
          <Landing/>
          <Services/>
          <AboutUs/>
          <ContactSection/>
          <Footer/>
      </div>
  )
}

export default App;

import React from 'react';

const ContactForm = () => {
    return (
        <div>
            <p className="fw-bold">For further inquiries or assistance, please get in touch with us via the form below.</p>
            <form>
                <div className="mb-3">
                    <input disabled="true" type="text" className="form-control" placeholder="Name (required)"/>
                </div>
                <div className="mb-3">
                    <input disabled="true" type="email" className="form-control"
                           placeholder="Email Address (required)"/>
                </div>
                <div className="mb-3">
                    <textarea disabled="true" className="form-control" rows="3"
                              placeholder="Message Detail"></textarea>
                </div>
                <button disabled="true" type="submit" className="btn btn-danger"
                        style={{width: '100%', backgroundColor: '#891E13', borderColor: '#891E13'}}>
                    Coming Soon
                </button>
            </form>
        </div>
    );
}

export default ContactForm;
